import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ActivityRegister } from '../../interfaces/activity-register.interface';
import * as myAccountActions from '../actions/my-account.actions';
import { UserInformation } from '../../interfaces/user-information.interface';

export interface MyAccountState extends EntityState<any> {
    selectedActivityId: string | null;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    error: string;
    activity: Array<ActivityRegister>;
    user: UserInformation;
}

export const defaultState: MyAccountState = {
    ids: [],
    entities: {},
    selectedActivityId: null,
    loading: false,
    loaded: false,
    error: "",
    activity: [],
    user: null
};

export const activityAdapter: EntityAdapter<ActivityRegister> = createEntityAdapter<ActivityRegister>();

export const ActivityInitialState = activityAdapter.getInitialState(defaultState);

const reducer = createReducer(ActivityInitialState,
    on(myAccountActions.successGetActivity, (state, { activity }) => (
        activityAdapter.setAll(activity, {
            ...state,
            loaded: true,
            loading: false,
            activity
        })
    )),
    on(myAccountActions.errorGetActivity, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(myAccountActions.successGetUserInformation, (state, { user }) => ({
        ...state, user
    })),
    on(myAccountActions.errorGetUserInformation, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(myAccountActions.errorUpdateUserInformation, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function myAccountReducer(state, action) {
    return reducer(state, action);
}