import { createReducer, createSelector, on } from '@ngrx/store';
import * as carrierActions from '../actions/carrier.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Carrier } from '../../interfaces/carrier.interface'
import { PaginationMetaData } from '@core/interfaces/pagination.interface';

export interface CarrierState extends EntityState<any> {
    selectedCarrierId: string | null;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    pagination: PaginationMetaData;
    error: string;
}

export const defaultState: CarrierState = {
    ids: [],
    entities: {},
    pagination: {} as PaginationMetaData,
    selectedCarrierId: null,
    loading: false,
    loaded: false,
    error: ""
};

export const carrierAdapter: EntityAdapter<Carrier> = createEntityAdapter<Carrier>();

export const CarrierInitialState = carrierAdapter.getInitialState(defaultState);

const reducer = createReducer(CarrierInitialState,
    on(carrierActions.successGetCarrier, (state, { carrier, pagination }) => (
        carrierAdapter.setAll(carrier, {
            ...state,
            pagination,
            loaded: true,
            loading: false,
        })
    )),
    on(carrierActions.errorGetCarrier, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(carrierActions.successCreateCarrier, (state, { carrier }) => (
        carrierAdapter.addOne(carrier, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(carrierActions.errorCreateCarrier, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(carrierActions.successUpdateCarrier, (state, { carrier }) => (
        carrierAdapter.updateOne({ id: carrier.id, changes: carrier }, state)
    )),
    on(carrierActions.errorUpdateCarrier, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function carrierReducer(state, action) {
    return reducer(state, action);
}