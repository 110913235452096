import { createReducer, on } from "@ngrx/store";
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { PaginationMetaData } from "@core/interfaces/pagination.interface";
import * as CitiesActions from "../actions/cities.actions";

export interface CitiesState {
  cities: any[];
  loading: boolean;
  pagination: PaginationMetaData;
  loaded: boolean;
  error: string;
}

export const defaultState: CitiesState = {
  cities: [],
  pagination: {} as PaginationMetaData,
  loading: false,
  loaded: false,
  error: "",
};

export const CitiesAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const CitiesInitialState = CitiesAdapter.getInitialState(defaultState);

const reducer = createReducer(
  CitiesInitialState,
  on(CitiesActions.successGetCities, (state, { cities, pagination }) => ({
    ...state,
    pagination,
    cities,
    loaded: true,
    loading: false,
  })),
  on(CitiesActions.errorGetCities, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload,
  })),
  on(CitiesActions.successCreateCities, (state, { city }) => ({
    ...state,
    cities: [...state.cities, city],
  })),
  on(CitiesActions.errorCreateCities, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload,
  }))
);

export function CitiesReducer(state, action) {
  return reducer(state, action);
}
