import { createAction, props } from "@ngrx/store";

export enum AwbConfirmActionsTypes {
  getAwbConfirm = "[AwbConfirm] Get Awb Confirm",
  successGetAwbConfirm = "[AwbConfirm] Success Get Awb Confirm",
  errorGetAwbConfirm = "[AwbConfirm] Error Get Awb Confirm",
}

export const getAwbConfirm = createAction(
  AwbConfirmActionsTypes.getAwbConfirm,
  props<{ filters: string }>()
);
export const successGetAwbConfirm = createAction(
  AwbConfirmActionsTypes.successGetAwbConfirm,
  props<{ awb_confirm: Array<any> }>()
);
export const errorGetAwbConfirm = createAction(
  AwbConfirmActionsTypes.errorGetAwbConfirm,
  props<{ payload: any }>()
);
