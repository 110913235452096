import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, exhaustMap, catchError, mergeMap } from 'rxjs/operators';
import { InitApp, createActivityError, createActivitySuccess } from '../actions/init.action';
import { GlobalService } from '@core/services/global.service';

@Injectable()
export class IniEffects {
  initApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitApp.Start),
      exhaustMap(() =>
        of(true).pipe(
          mergeMap(() => []),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createActivity$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(InitApp.CreateActivity),
      mergeMap(({ activity }) => this.globalServices.createActivity(activity)
        .pipe(
          map((activity: any) => createActivitySuccess({ activity })),
          catchError(err => of(createActivityError({ payload: err })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private globalServices: GlobalService
  ) { }
}
