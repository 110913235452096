import { createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ActivityRegister } from "../../interfaces/activity-register.interface";
import * as myAccountActions from "../actions/my-account.actions";
import { UserInformation } from "../../interfaces/user-information.interface";
import { PaginationMetaData } from "@core/interfaces/pagination.interface";

export interface MyAccountState extends EntityState<any> {
  selectedActivityId: string | null;
  ids: Array<any>;
  loading: boolean;
  loaded: boolean;
  error: string;
  pagination: PaginationMetaData;
  activity: Array<ActivityRegister>;
  user: UserInformation;
  passwordChangeStatus: string;
  profileImageUrl: string | null;
}

export const defaultState: MyAccountState = {
  ids: [],
  entities: {},
  selectedActivityId: null,
  pagination: {} as PaginationMetaData,
  loading: false,
  loaded: false,
  error: "",
  activity: [],
  user: null,
  passwordChangeStatus: "idle",
  profileImageUrl: null,
};

export const activityAdapter: EntityAdapter<ActivityRegister> =
  createEntityAdapter<ActivityRegister>();

export const ActivityInitialState =
  activityAdapter.getInitialState(defaultState);

const reducer = createReducer(
  ActivityInitialState,
  on(myAccountActions.successGetActivity, (state, { activity, pagination }) =>
    activityAdapter.setAll(activity, {
      ...state,
      pagination,
      loaded: true,
      loading: false,
      activity,
    })
  ),
  on(myAccountActions.errorGetActivity, (state, { payload }) => ({
    ...state,
    entities: {},
    loaded: false,
    loading: false,
    error: payload,
  })),
  on(myAccountActions.successGetUserInformation, (state, { user }) => ({
    ...state,
    user,
  })),
  on(myAccountActions.errorGetUserInformation, (state, { payload }) => ({
    ...state,
    entities: {},
    loaded: false,
    loading: false,
    error: payload,
  })),
  on(myAccountActions.errorUpdateUserInformation, (state, { payload }) => ({
    ...state,
    entities: {},
    loaded: false,
    loading: false,
    error: payload,
  })),
  on(myAccountActions.successUpdateImageProfile, (state, { image }) => ({
    ...state,
    user: {
      ...state.user,
      profile_image: image,
    },
  })),

  on(myAccountActions.GetProfileImageUrlSuccess, (state, { imageUrl }) => ({
    ...state,
    profileImageUrl: imageUrl,
  })),

  on(myAccountActions.GetProfileImageUrlFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(myAccountActions.SuccessChangePassword, (state) => ({
    ...state,
    passwordChangeStatus: "success",
  })),
  on(myAccountActions.ErrorChangePassword, (state, { payload }) => ({
    ...state,
    passwordChangeStatus: "error",
    error: payload,
  })),
  on(myAccountActions.ResetPasswordChangeStatus, (state) => ({
    ...state,
    passwordChangeStatus: "idle",
  }))
);

export function myAccountReducer(state, action) {
  return reducer(state, action);
}
