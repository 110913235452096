import {Component,} from '@angular/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  template: `
    <div>
      not found
    </div>
  `,
  styles: [``]
})
export class NotFoundComponent { }
