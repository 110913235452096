import { createReducer, on } from '@ngrx/store';
import * as boxsummaryActions from '../actions/box-summary.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BoxSummary } from '../../interfaces/box-summary.interface';

export interface BoxSummaryState extends EntityState<any> {
    selectedBoxSummaryId: string | null;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultState: BoxSummaryState = {
    ids: [],
    entities: {},
    selectedBoxSummaryId: null,
    loading: false,
    loaded: false,
    error: ""
};

export const boxsummaryAdapter: EntityAdapter<BoxSummary> = createEntityAdapter<BoxSummary>();

export const BoxSummaryInitialState = boxsummaryAdapter.getInitialState(defaultState);

const reducer = createReducer(BoxSummaryInitialState,
    on(boxsummaryActions.successGetBoxSummary, (state, { boxsummary }) => (
        boxsummaryAdapter.setAll(boxsummary, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(boxsummaryActions.errorGetBoxSummary, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function boxsummaryReducer(state, action) {
    return reducer(state, action);
}