import { Action, createReducer, on } from "@ngrx/store";
import * as BoxTypeActions from "../actions/box-type.actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { PaginationMetaData } from "@core/interfaces/pagination.interface";

export interface BoxTypeState extends EntityState<any> {
  ids: Array<string>;
  loading: boolean;
  pagination: PaginationMetaData;
  loaded: boolean;
  error: string;
}

export const defaultState: BoxTypeState = {
  ids: [],
  entities: {},
  pagination: {} as PaginationMetaData,
  loading: false,
  loaded: false,
  error: "",
};

export const BoxTypeAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const BoxTypeInitialState = BoxTypeAdapter.getInitialState(defaultState);

const reducer = createReducer(
  BoxTypeInitialState,
  on(BoxTypeActions.successGetBoxType, (state, { boxType, pagination }) =>
    BoxTypeAdapter.setAll(boxType, {
      ...state,
      pagination,
      loaded: true,
      loading: false,
    })
  ),
  on(BoxTypeActions.errorGetBoxType, (state, { payload }) => ({
    ...state,
    error: payload,
    entities: {},
    loaded: false,
    loading: false,
  })),
  on(BoxTypeActions.successCreateBoxType, (state, { boxType }) =>
    BoxTypeAdapter.addOne(boxType, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(BoxTypeActions.errorCreateBoxType, (state, { payload }) => ({
    ...state,
    error: payload,
    entities: {},
    loaded: false,
    loading: false,
  }))
);

export function boxTypeReducer(
  state: EntityState<any> & BoxTypeState,
  action: Action<string>
) {
  return reducer(state, action);
}
