import { createReducer, createSelector, on } from '@ngrx/store';
import * as awbShippingActions from '../actions/awb-shipping.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AwbShipping } from '../../interfaces/awb-shipping.interface'

export interface AwbShippingState extends EntityState<any> {
    selectedAwbShippingId: string | null;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultState: AwbShippingState = {
    ids: [],
    entities: {},
    selectedAwbShippingId: null,
    loading: false,
    loaded: false,
    error: ""
};

export const awbShippingAdapter: EntityAdapter<AwbShipping> = createEntityAdapter<AwbShipping>();

export const AwbShippingInitialState = awbShippingAdapter.getInitialState(defaultState);

const reducer = createReducer(AwbShippingInitialState,
    on(awbShippingActions.successGetAwbShipping, (state, { awb_shipping }) => (
        awbShippingAdapter.setAll(awb_shipping, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(awbShippingActions.errorGetAwbShipping, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(awbShippingActions.successCreateAwb, (state, { Awb }) => (
        awbShippingAdapter.addOne(Awb, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(awbShippingActions.errorCreateAwb, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(awbShippingActions.successUpdateAwb, (state, { Awb }) => (
        awbShippingAdapter.updateOne({ id: Awb.id, changes: Awb }, state)
    )),
    on(awbShippingActions.errorUpdateAwb, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function AwbShippingReducer(state, action) {
    return reducer(state, action);
}