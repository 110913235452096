import { createReducer, createSelector, on } from '@ngrx/store';
import * as consigneeActions from '../actions/consignee.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Consignee } from '../../interfaces/consignee.interface';

export interface ConsigneeState extends EntityState<Consignee> {
    selectedConsigneeId: string | null;
    ids: Array<string>;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultState: ConsigneeState = {
    ids: [],
    entities: {},
    selectedConsigneeId: null,
    loading: false,
    loaded: false,
    error: ""
};

export const consigneeAdapter: EntityAdapter<Consignee> = createEntityAdapter<Consignee>();

export const ConsigneeInitialState = consigneeAdapter.getInitialState(defaultState);

const reducer = createReducer(ConsigneeInitialState,
    on(consigneeActions.successGetConsignee, (state, { consignee }) => (
        consigneeAdapter.setAll(consignee, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(consigneeActions.errorGetConsignee, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(consigneeActions.successCreateConsignee, (state, { consignee }) => (
        consigneeAdapter.addOne(consignee, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(consigneeActions.errorCreateConsignee, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(consigneeActions.successUpdateConsignee, (state, { consignee }) => (
        consigneeAdapter.updateOne({ id: consignee.id, changes: consignee }, state)
    )),
    on(consigneeActions.errorUpdateConsignee, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function consigneeReducer(state, action) {
    return reducer(state, action);
}