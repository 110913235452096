import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'boilerplate-app';

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    // Inicializa el tema al cargar la aplicación
    this.themeService.loadTheme();
  }


}
