import { createReducer, createSelector, on } from '@ngrx/store';
import * as layoutActions from '../actions/Layout.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Menu } from '../../interfaces/menu.interface';

export interface LayoutState extends EntityState<any> {
    selectedLayoutId: string | null;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    error: string;
    menu: Menu;

}

export const defaultState: LayoutState = {
    ids: [],
    entities: {},
    selectedLayoutId: null,
    loading: false,
    loaded: false,
    error: "",
    menu: null
};

export const layoutAdapter: EntityAdapter<Menu> = createEntityAdapter<Menu>();

export const MenuInitialState = layoutAdapter.getInitialState(defaultState);

const reducer = createReducer(MenuInitialState,
    on(layoutActions.successGetMenu, (state, { menu }) => ({
        ...state, menu
    })),
    on(layoutActions.errorGetMenu, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function layoutReducer(state, action) {
    return reducer(state, action);
}