import { createReducer, on } from '@ngrx/store';
import * as housesummaryActions from '../actions/house-summary.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HouseSummary } from '../../interfaces/house-summary.interface';

export interface HouseSummaryState extends EntityState<any> {
    selectedHouseSummaryId: string | null;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultState: HouseSummaryState = {
    ids: [],
    entities: {},
    selectedHouseSummaryId: null,
    loading: false,
    loaded: false,
    error: ""
};

export const housesummaryAdapter: EntityAdapter<HouseSummary> = createEntityAdapter<HouseSummary>();

export const HouseSummaryInitialState = housesummaryAdapter.getInitialState(defaultState);

const reducer = createReducer(HouseSummaryInitialState,
    on(housesummaryActions.successGetHouseSummary, (state, { housesummary }) => (
        housesummaryAdapter.setAll(housesummary, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(housesummaryActions.errorGetHouseSummary, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function housesummaryReducer(state, action) {
    return reducer(state, action);
}