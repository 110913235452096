import { EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as homeActions from "../actions/home.actions";
import { ChartOptions } from "../../home.component";

export interface HomeState extends EntityState<any> {
  chartsData: Partial<ChartOptions>;
}

export const defaultState: HomeState = {
  entities: {},
  ids: [],
  chartsData: {},
};

const reducer = createReducer(
  defaultState,
  on(homeActions.successGetChartData, (state, { data }) => ({
    ...state,
    chartsData: data,
  }))
);

export function homeReducer(state: HomeState, action: Action<string>) {
  return reducer(state, action);
}
