import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { localEffects } from "./app.effects";
import { appReducers } from "./app.reducers";
import { environment } from "@environments/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { GlobalService } from "./services/global.service";
import { GlobalModel } from "./models/global.model";
import { FormDynamicService } from "./services/form-dynamic.service";
import { ExcelService } from "./services/excel.service";

@NgModule({
  imports: [
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(localEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [GlobalService, GlobalModel, FormDynamicService, ExcelService],
})
export class CoreModule {}
