import { createReducer, createSelector, on } from '@ngrx/store';
import * as driverActions from '../actions/driver.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {Driver} from '../../interfaces/driver.interface'
import { PaginationMetaData } from '@core/interfaces/pagination.interface';

export interface DriverState extends EntityState<any> {
    selectedDriverId: string | null;
    ids: Array<any>;
    loading: boolean;
    pagination: PaginationMetaData;
    loaded: boolean;
    error: string;
}

export const defaultState: DriverState = {
    ids: [],
    entities: {},
    selectedDriverId: null,
    pagination: {} as PaginationMetaData,
    loading: false,
    loaded: false,
    error: ""
};

export const driverAdapter: EntityAdapter<Driver> = createEntityAdapter<Driver>();

export const DriverInitialState = driverAdapter.getInitialState(defaultState);

const reducer = createReducer(DriverInitialState,
    on(driverActions.successGetDriver, (state, { driver, pagination }) => (
        driverAdapter.setAll(driver, {
            ...state,
            pagination,
            loaded: true,
            loading: false,
        })
    )),
    on(driverActions.errorGetDriver, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(driverActions.successCreateDriver, (state, { driver }) => (
        driverAdapter.addOne(driver, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(driverActions.errorCreateDriver, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(driverActions.successUpdateDriver, (state, { driver }) => (
        driverAdapter.updateOne({ id: driver.id, changes: driver }, state)
    )),
    on(driverActions.errorUpdateDriver, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function driverReducer(state, action) {
    return reducer(state, action);
}