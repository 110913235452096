import { createReducer, on } from "@ngrx/store";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { PaginationMetaData } from "@core/interfaces/pagination.interface";
import * as CountriesActions from "../actions/countries.actions";
import { Countries } from "../../interface/countries.interface";

export interface CountriesState extends EntityState<Countries> {
  countries: Countries[];
  loading: boolean;
  pagination: PaginationMetaData;
  loaded: boolean;
  error: string;
}

export const defaultState: CountriesState = {
  entities: {},
  ids: [],
  countries: [],
  pagination: {} as PaginationMetaData,
  loading: false,
  loaded: false,
  error: "",
};

export const CountriesAdapter: EntityAdapter<Countries> =
  createEntityAdapter<Countries>();

export const CountriesInitialState =
  CountriesAdapter.getInitialState(defaultState);

const reducer = createReducer(
  CountriesInitialState,
  on(
    CountriesActions.successGetCountries,
    (state, { countries, pagination }) => ({
      ...state,
      pagination,
      countries,
      loaded: true,
      loading: false,
    })
  ),
  on(CountriesActions.errorGetCountries, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload,
  })),
  on(CountriesActions.successCreateCountries, (state, { country }) => ({
    ...state,
    countries: [...state.countries, country],
  })),
  on(CountriesActions.errorCreateCountries, (state, { payload }) => ({
    ...state,
    loaded: false,
    loading: false,
    error: payload,
  }))
);

export function CountriesReducer(state, action) {
  return reducer(state, action);
}
