import { createReducer, on } from '@ngrx/store';
import * as userActions from '../actions/user.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {User} from '../../interfaces/user.interface'
import { PaginationMetaData } from '@core/interfaces/pagination.interface';

export interface UserState extends EntityState<any> {
    selectedUserId: string | null;
    ids: Array<any>;
    loading: boolean;
    pagination: PaginationMetaData;
    loaded: boolean;
    error: string;
}

export const defaultState: UserState = {
    ids: [],
    entities: {},
    selectedUserId: null,
    pagination: {} as PaginationMetaData,
    loading: false,
    loaded: false,
    error: ""
};

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>();

export const UserInitialState = userAdapter.getInitialState(defaultState);

const reducer = createReducer(UserInitialState,
    on(userActions.successGetUser, (state, { user, pagination }) => (
        userAdapter.setAll(user, {
            ...state,
            pagination,
            loaded: true,
            loading: false,
        })
    )),
    on(userActions.errorGetUser, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(userActions.successCreateUser, (state, { user }) => (
        userAdapter.addOne(user, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(userActions.errorCreateUser, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(userActions.successUpdateUser, (state, { user }) => (
        userAdapter.updateOne({ id: user.id, changes: user }, state)
    )),
    on(userActions.errorUpdateUser, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function userReducer(state, action) {
    return reducer(state, action);
}