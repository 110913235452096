import { createReducer, createSelector, on } from '@ngrx/store';
import * as dimensionActions from '../actions/dimension.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Dimension } from '../../interfaces/dimension.interface';

export interface DimensionState extends EntityState<Dimension> {
    selectedDimensionId: string | null;
    ids: Array<string>;
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const defaultState: DimensionState = {
    ids: [],
    entities: {},
    selectedDimensionId: null,
    loading: false,
    loaded: false,
    error: ""
};

export const dimensionAdapter: EntityAdapter<Dimension> = createEntityAdapter<Dimension>();

export const DimensionInitialState = dimensionAdapter.getInitialState(defaultState);

const reducer = createReducer(DimensionInitialState,
    on(dimensionActions.successGetDimension, (state, { dimension }) => (
        dimensionAdapter.setAll(dimension, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(dimensionActions.errorGetDimension, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(dimensionActions.successCreateDimension, (state, { dimension }) => (
        dimensionAdapter.addOne(dimension, {
            ...state,
            loaded: true,
            loading: false,
        })
    )),
    on(dimensionActions.errorCreateDimension, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(dimensionActions.successUpdateDimension, (state, { dimension }) => (
        dimensionAdapter.updateOne({ id: dimension.id, changes: dimension }, state)
    )),
    on(dimensionActions.errorUpdateDimension, (state, { payload }) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
);

export function dimensionReducer(state, action) {
    return reducer(state, action);
}