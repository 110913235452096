import { ActionReducerMap } from "@ngrx/store";
import { AppState } from "./store/interfaces/app.interface";
import { layoutReducer } from "@app/modules/layout/store/reducers/Layout.reducers";
import { consigneeReducer } from "@app/modules/consignee/store/reducers/consignee.reducers";
import { myAccountReducer } from "@app/modules/my-accounts/store/reducers/my-account.reducers";
import { authReducer } from "@app/modules/auth/store/reducers/auth.reducers";
import { carrierReducer } from "@app/modules/carrier/store/reducers/carrier.reducers";
import { growerReducer } from "@app/modules/grower/store/reducers/grower.reducers";
import { driverReducer } from "@app/modules/driver/store/reducers/driver.reducers";
import { productReducer } from "@app/modules/product/store/reducers/product.reducers";
import { dimensionReducer } from "@app/modules/dimension/store/reducers/dimension.reducers";
import { userReducer } from "@app/modules/user/store/reducers/user.reducers";
import { shiptoReducer } from "@app/modules/consignee/store/reducers/ship-to.reducers";
import { AwbShippingReducer } from "@app/modules/awb-shipping/store/reducers/awb-shipping.reducers";
import { boxsummaryReducer } from "@app/modules/box-summary/store/reducers/box-summary.reducers";
import { housesummaryReducer } from "@app/modules/house-summary/store/reducers/house-summary.reducers";
import { AssignCarrierReducer } from "@app/modules/assign-carrier/store/reducers/assign-carrier.reducers";
import { PrintBolReducer } from "@app/modules/print-bol/store/reducers/print-bol.reducers";
import { homeReducer } from "@app/modules/home/store/reducers/home.reducers";
import { inventorySummaryReducer } from "@app/modules/inventory-summary/store/reducers/inventory-summary.reducers";
import { awbSummaryReducer } from "@app/modules/awb-summary/store/reducer/awb-summar.reducer";
import { CountriesReducer } from "@app/modules/countries-cities/store/reducers/countries.reducers";
import { CitiesReducer } from "@app/modules/countries-cities/store/reducers/cities.reducer";
import { boxTypeReducer } from "@app/modules/dimension/store/reducers/box-type.reducer";
import { AwbConfirmReducer } from "@app/modules/awb-confirm/store/reducers/awb-confirm.reducers";

export const appReducers: ActionReducerMap<AppState | any> = {
  auth: authReducer,
  home: homeReducer,
  layout: layoutReducer,
  myAccount: myAccountReducer,
  consignee: consigneeReducer,
  carrier: carrierReducer,
  grower: growerReducer,
  driver: driverReducer,
  product: productReducer,
  dimension: dimensionReducer,
  boxType: boxTypeReducer,
  user: userReducer,
  shipto: shiptoReducer,
  awbShipping: AwbShippingReducer,
  awbConfirm: AwbConfirmReducer,
  boxsummary: boxsummaryReducer,
  housesummary: housesummaryReducer,
  assignCarrier: AssignCarrierReducer,
  printBol: PrintBolReducer,
  inventorySummary: inventorySummaryReducer,
  awbSummary: awbSummaryReducer,
  countries: CountriesReducer,
  cities: CitiesReducer,
};
