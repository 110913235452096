import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CoreModule } from './core/core.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import en from '@angular/common/locales/en';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { AuthInterceptorService } from '@core/services/interceptor/intercept.services';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

registerLocaleData(en);

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [RouterModule,
        AppRoutingModule,
        CommonModule,
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        ScrollingModule,
        DragDropModule,
        NzNotificationModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        { provide: NZ_ICONS, useValue: icons },
        { provide: NZ_I18N, useValue: en_US },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
