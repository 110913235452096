import { createReducer, on } from '@ngrx/store';
import * as authActions from '../actions/auth.actions';
import * as recoverActions from '../actions/forgot-password.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { login } from '../../interfaces/login.interface';

export interface AuthState extends EntityState<any> {
    selectedAuthId: string | null;
    ids: Array<any>;
    loading: boolean;
    loaded: boolean;
    error: string;
    logged: boolean;
    recovered: boolean;
}

export const defaultState: AuthState = {
    ids: [],
    entities: {},
    selectedAuthId: null,
    loading: false,
    loaded: false,
    error: "",
    logged: null,
    recovered: null
};

export const authAdapter: EntityAdapter<login> = createEntityAdapter<login>();

export const AuthInitialState = authAdapter.getInitialState(defaultState);

const reducer = createReducer(AuthInitialState,
    on(authActions.successloginUser, (state, { logged }) => (
        { ...state, logged }
    )),
    on(authActions.errorLoginUser, (state, { logged }) => (
        { ...state, logged }
    )),
    on(authActions.resetLogged, (state, { }) => (
        { ...state, logged: null }
    )),
    on(recoverActions.sucessVerifyCode, (state, { recovered }) => (
        { ...state, recovered }
    )),
    on(recoverActions.errorVerifyCode, (state, { recovered }) => (
        { ...state, recovered }
    )),
    on(recoverActions.resetForgot, (state, { }) => (
        { ...state, recovered: null }
    )),
);

export function authReducer(state, action) {
    return reducer(state, action);
}