import { createReducer, createSelector, on } from '@ngrx/store';
import * as shiptoActions from '../actions/ship-to.actions'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {Shipto} from '../../interfaces/ship-to.interface';
import { PaginationMetaData } from '@core/interfaces/pagination.interface';

export interface ShipToState extends EntityState<Shipto>{
    selectedShipToId: string | null;
    pagination: PaginationMetaData;
    ids: Array<string>;
    loading: boolean;
    loaded: boolean;
    error: string;
}
export const defaultState:ShipToState = {
    ids: [],
    entities: {},
    pagination: {} as PaginationMetaData,
    selectedShipToId: null,
    loading: false,
    loaded: false,
    error: ""
};
export const shiptoAdapter: EntityAdapter<Shipto> = createEntityAdapter<Shipto>();

export const ShiptoInitialState = shiptoAdapter.getInitialState(defaultState);

const reducer = createReducer(ShiptoInitialState,
    on(shiptoActions.successGetShipTo, (state, {shipto, pagination}) =>(
        shiptoAdapter.setAll(shipto, {
            ...state,
            loaded:true,
            pagination,
            loading:false,
        })
    )),
    on(shiptoActions.errorGetShipTo, (state, {payload}) =>({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
    on(shiptoActions.successCreateShipTo, (state,{shipto})=> (
        shiptoAdapter.addOne(shipto,{
            ...state,
            loaded:true,
            loading:false,
        })
    )),
    on(shiptoActions.errorCreateShipTo, (state, {payload}) => ({
        ...state,
        loaded: false,
        loading: false,
        error: payload
    })),
    on(shiptoActions.successUpdateShipTo, (state, {shipto})=> (
        shiptoAdapter.updateOne({id: shipto.id, changes: shipto},state)
    )),
    on(shiptoActions.errorUpdateShipTo, (state, {payload}) => ({
        ...state,
        entities: {},
        loaded: false,
        loading: false,
        error: payload
    })),
)
export function shiptoReducer(state, action){
    return reducer(state, action)
}