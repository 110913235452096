import { Action, createReducer, createSelector, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import * as AwbConfirmActions from "../actions/awv-confirm.actions";
import { PaginationMetaData } from "@core/interfaces/pagination.interface";

export interface AwbConfirmState extends EntityState<any> {
  ids: Array<any>;
  loading: boolean;
  pagination: PaginationMetaData;
  loaded: boolean;
  error: string;
}

export const defaultState: AwbConfirmState = {
  ids: [],
  entities: {},
  pagination: {} as PaginationMetaData,
  loading: false,
  loaded: false,
  error: "",
};

export const awbConfirmAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const AwbConfirmInitialState =
  awbConfirmAdapter.getInitialState(defaultState);

const reducer = createReducer(
  AwbConfirmInitialState,
  on(AwbConfirmActions.successGetAwbConfirm, (state, { awb_confirm }) =>
    awbConfirmAdapter.setAll(awb_confirm, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(AwbConfirmActions.errorGetAwbConfirm, (state, { payload }) => ({
    ...state,
    entities: {},
    loaded: false,
    loading: false,
    error: payload,
  }))
);

export function AwbConfirmReducer(
  state: EntityState<any> & AwbConfirmState,
  action: Action<string>
) {
  return reducer(state, action);
}
